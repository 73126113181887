<template>
  <div>
    <div class="page-name">
      <span>
        <i class="fas fa-map-marker-alt"></i>
        {{ i18n("Custom.ExportTestResults") }}
      </span>
    </div>

    <div class="filter-block">
      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.IsQueryLatestTests") }} </span>
        <el-select
          style="display: block"
          v-model="searchForm.IsLastTest"
          :placeholder="i18n('Basic.PleaseSelect')"
        >
          <el-option :value="true" :label="i18n('Basic.Yes')"></el-option>
          <el-option :value="false" :label="i18n('Basic.No')"></el-option>
        </el-select>
      </div>
    </div>

    <!-- <div class="text-right mb-2">
      <a class="btn btn-blue" @click.prevent="openReleaseModal(true)">新增</a>
    </div> -->

    <div class="d-flex justify-content-end mb-2">
      <a class="btn btn-yellow mr-2" @click.prevent="getDataList">{{
        i18n("Basic.Search")
      }}</a>

      <button
        class="btn btn-sm btn-info mr-2"
        style="position: relative"
        @click.prevent="openUploadModal"
      >
        <span
          v-if="searchForm.EmpNo && searchForm.EmpNo.length > 0"
          style="
            position: absolute;
            right: -5px;
            top: -5px;
            background-color: #ff0000;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            text-align: center;
            line-height: 20px;
            font-size: 0.8em;
          "
          >{{ searchForm.EmpNo.length }}</span
        >
        {{ i18n("Custom.ImportEmployeeNumber") }}
      </button>
      <button
        v-if="dataList.length > 0"
        class="btn btn-sm btn-info mr-2"
        @click.prevent="downloadPersonExcel"
      >
        {{ i18n("Custom.ExportPDFOnePerson") }}
      </button>
      <button
        v-if="dataList.length > 0"
        class="btn btn-sm btn-info mr-2"
        @click.prevent="downloadMultiplePersonExcel"
      >
        {{ i18n("Custom.ExportPDFMultiplePerson") }}
      </button>
      <button
        v-if="dataList.length > 0"
        class="btn btn-sm btn-info mr-2"
        @click.prevent="exportData"
      >
        {{ i18n("Basic.ExportExcel") }}
      </button>
    </div>

    <vue-bootstrap4-table
      :rows="dataList"
      :columns="columns"
      :config="config"
      :classes="classes"
    >
      <template slot="finishTime" slot-scope="props">
        <span v-if="props.row.FinishTime">
          {{ dayjs(props.row.FinishTime).format("YYYY-MM-DD HH:mm") }}
        </span>
      </template>
      <template slot="func" slot-scope="props">
        <button
          class="btn btn-sm btn-blue mr-2"
          @click.prevent="openDetailModal(props.row)"
        >
          {{ i18n("Basic.View") }}
        </button>

        <button
          class="btn btn-sm btn-blue mr-2"
          @click.prevent="openPatternModal(props.row)"
        >
          {{ i18n("Custom.ViewCorrespondingTemplate") }}
        </button>
      </template>
    </vue-bootstrap4-table>

    <!-- Release modal -->
    <div class="modal fade" id="detailModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <el-form
          :model="detailModal"
          label-position="top"
          ref="detailModal"
          class="modal-content"
        >
          <div class="modal-header">{{ i18n("Result.TestResult") }}</div>
          <div class="modal-body">
            <table class="info-table">
              <tr>
                <th>{{ i18n("Custom.CompanyName") }}</th>
                <td>{{ detailModal.CompanyName }}</td>
                <th>{{ i18n("Basic.Department") }}</th>
                <td>{{ detailModal.DepartmentName }}</td>
              </tr>
              <tr>
                <th>{{ i18n("Custom.JobNumber") }}</th>
                <td>{{ detailModal.EmpNo }}</td>
                <th>{{ i18n("Release.EmployeeName") }}</th>
                <td>{{ detailModal.EmpCName }}</td>
              </tr>
              <tr>
                <th>{{ i18n("Custom.QuizPostName") }}</th>
                <td>{{ detailModal.ReleaseName }}</td>
                <th>{{ i18n("Custom.QuizDate") }}</th>
                <td>{{ detailModal.FinishTime }}</td>
              </tr>
              <tr>
                <th>{{ i18n("Custom.JobTitle") }}</th>
                <td colspan="3">{{ detailModal.TitleName }}</td>
              </tr>
            </table>

            <table class="table table-striped table-light table-hover">
              <thead>
                <tr>
                  <th style="vertical-align: middle; width: 70px; text-align: center">
                    {{ i18n("Custom.QuestionNumber") }}
                  </th>
                  <th style="vertical-align: middle">{{ i18n("Custom.Topic") }}</th>
                  <th style="vertical-align: middle">
                    {{ i18n("Custom.SubjectOptions") }}
                  </th>
                  <th style="vertical-align: middle">
                    {{ i18n("Custom.CorrespondingPersonalityTraits") }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="Question in detailModal.ResultAnswers" :key="Question.SeqNo">
                  <td style="text-align: center">{{ Question.SeqNo }}</td>
                  <td>{{ Question.Question }}</td>
                  <td>{{ Question.Option }}</td>
                  <td style="text-align: center">{{ Question.Personality }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ i18n("Basic.Close") }}
            </button>
          </div>
        </el-form>
      </div>
    </div>

    <!-- 對應模板 modal -->
    <el-dialog
      :title="i18n('Custom.CorrespondingTemplate')"
      :visible.sync="dialogVisible"
      width="80%"
      top="40px"
    >
      <TraitComponent :patternModal="patternModal" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ i18n("Basic.Close") }}</el-button>
      </span>
    </el-dialog>

    <!-- 人員匯入資料查詢 Modal-->
    <el-dialog
      :title="i18n('Custom.PersonnelImport')"
      :visible.sync="dialogUploadVisible"
    >
      <div>
        <u style="color: midnightblue; cursor: pointer" @click.prevent="downloadFile">{{
          i18n("Custom.TemplateDownload")
        }}</u>

        <div style="display: flex">
          <el-upload
            ref="upload"
            action
            accept=".xls,.xlsx"
            :auto-upload="false"
            :show-file-list="true"
            :on-change="uploadFile"
            :limit="1"
          >
            <el-button size="small" type="primary">{{
              i18n("Basic.ClickUpload")
            }}</el-button>
          </el-upload>

          <div style="flex: 1; min-width: 1px; margin-left: 10px">
            <pre>{{ uploadNote }}</pre>
          </div>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="dialogUploadVisible = false">{{
          i18n("Basic.Cancel")
        }}</el-button>
        <el-button type="primary" @click="sendUpload()">{{
          i18n("Basic.Confirm")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TraitComponent from "@/components/Result/TraitComponent";
import $ from "jquery";

export default {
  data() {
    return {
      dialogUploadVisible: false, //人員匯入modal
      uploadTemp: {}, //匯入暫存
      options: [
        {
          label: "面試人員",
          options: [
            {
              value: 4,
              label: "面試人員",
            },
          ],
        },
        {
          label: "在職同仁",
          options: [
            {
              value: 3,
              label: "在職同仁",
            },
          ],
        },
        {
          label: "離職同仁",
          options: [
            {
              value: 0,
              label: "離職",
            },
            {
              value: 2,
              label: "退休",
            },
            {
              value: 1,
              label: "留停",
            },
          ],
        },
      ],
      dialogVisible: false,
      screen: null, // loading遮罩
      detailModal: {},
      patternModal: {}, //匹配模板modal
      config: {
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 10,
        per_page_options: [10, 25, 50],
        global_search: {
          visibility: false,
        },
        // checkbox_rows: true,
      },
      dataList: [],
      releaseList: [],
      companyList: [],
      departmentList: [],
      titleList: [],
      sexList: [],
      degreeList: [],
      schoolList: [],
      natureList: [],
      virtualEmpList: [],
      keepEmpNoForExport: [],
      searchForm: {
        IsLastTest: true,
        DepartmentGuids: [],
        TitleNames: [],
        Categorys: [],
        DegreeIds: [],
        NatureGuids: [],
        SchoolNames: [],
        IsInterview: [],
        Sexs: [],
        EmpStaus: [],
        EmpNo: [], //匯入的人
      },

      classes: {
        table: "table-light table-striped",
        // via: 'table-danger'
      },

      searchNote: "",
      uploadNote: "",

      //分類
      categoryOptions: [],
      params: [],
    };
  },
  components: {
    TraitComponent,
  },
  computed: {
    columns() {
      const col = [
        {
          label: this.i18n("Custom.SerialNumber"),
          name: "No",
          sort: true,
        },
        {
          label: this.i18n("Custom.CompanyName"),
          name: "CompanyName",
          sort: true,
        },
        {
          label: this.i18n("Basic.Department"),
          name: "DepartmentName",
          sort: true,
        },
        {
          label: this.i18n("Custom.JobNumber"),
          name: "EmpNo",
          sort: true,
        },
        {
          label: this.i18n("Release.EmployeeName"),
          name: "EmpCName",
          sort: true,
        },
        {
          label: this.i18n("Custom.PersonalityClassification"),
          name: "Category",
          sort: true,
        },
        {
          label: this.i18n("Custom.JobTitle"),
          name: "TitleName",
          sort: true,
        },
        {
          label: this.i18n("Release.QuizNumber"),
          name: "SeqNo",
          sort: true,
        },
        {
          label: this.i18n("Custom.QuizPostName"),
          name: "ReleaseName",
          sort: true,
        },
        {
          label: this.i18n("Custom.FinishTime"),
          name: "FinishTime",
          sort: true,
          slot_name: "finishTime",
        },
        {
          label: this.i18n("Custom.Function"),
          name: "func",
          slot_name: "func",
        },
      ];
      return col;
    },
  },
  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    // 下載範例檔
    downloadFile() {
      const vm = this;
      vm.screen = this.openLoading();
      const api = `${window.BaseUrl.api}/Eva/Result/ExcelToPerson`;
      vm.$http
        .get(api, { responseType: "blob" })
        .then((response) => {
          const filename = decodeURIComponent(
            decodeURIComponent(
              response.headers["content-disposition"].split("filename*=UTF-8''")[1]
            )
          );

          const blob = new Blob([response.data]);
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    // 打開匯入modal
    openUploadModal() {
      const vm = this;
      vm.dialogUploadVisible = true;
      vm.$nextTick(function () {
        vm.$refs.upload.clearFiles();
      });
    },

    // 匯入資料 API
    uploadFile(file) {
      const vm = this;
      let fd = new FormData();
      fd.append("file", file.raw);
      vm.uploadTemp = fd;
    },
    downloadPersonExcel() {
      this.screen = this.openLoading(); // 開啟 loading

      const downloadPromises = this.dataList.map((item) => {
        const url = `${window.BaseUrl.api}/Eva/Result/Pattern/Pdf/${item.EvaluationPersonGuid}`;
        return this.$http
          .get(url, { responseType: "blob" })
          .then((response) => {
            const contentType = response.headers["content-type"];

            if (contentType.includes("application/json")) {
              // 如果是 JSON 格式，解析錯誤訊息
              const reader = new FileReader();
              reader.onload = () => {
                try {
                  const result = JSON.parse(reader.result);
                  this.$store.dispatch("alertMessageModule/updateMessage", {
                    message: result.ErrorMessage || "未知錯誤",
                    status: "danger",
                  });
                } catch {
                  this.$store.dispatch("alertMessageModule/updateMessage", {
                    message: "回傳資料解析錯誤",
                    status: "danger",
                  });
                }
              };
              reader.readAsText(response.data);
            } else {
              // 如果是 Blob，處理下載
              const filename = decodeURIComponent(
                decodeURIComponent(
                  response.headers["content-disposition"].split("filename*=UTF-8''")[1]
                )
              );

              if (filename !== "空檔.pdf") {
                const sanitizedFilename = filename.replace(/\.[^.]+$/, ".pdf");

                const blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = sanitizedFilename;
                a.click();
                window.URL.revokeObjectURL(url);
              }
            }
          })
          .catch(() => {
            this.$store.dispatch("alertMessageModule/updateMessage", {
              message: "下載失敗",
              status: "danger",
            });
          });
      });

      // 等待所有的 Promise 完成
      Promise.all(downloadPromises)
        .then(() => {
          this.screen.close(); // 關閉 loading
        })
        .catch(() => {
          this.$store.dispatch("alertMessageModule/updateMessage", {
            message: "部分下載失敗，請重試",
            status: "danger",
          });
          this.screen.close();
        });
    },

    //匯出多人
    downloadMultiplePersonExcel() {
      this.screen = this.openLoading();

      let params = {
        ...this.searchForm,
        EmpNo: this.keepEmpNoForExport,
      };

      const url = `${window.BaseUrl.api}/Eva/Result/Pattern/Pdf/Multi`;
      this.$http
        .post(url, params, { responseType: "blob" })
        .then(async (response) => {
          const contentType = response.headers["content-type"];

          if (contentType.includes("application/json")) {
            // 如果是 JSON，嘗試解析錯誤訊息
            const reader = new FileReader();
            reader.onload = () => {
              try {
                const result = JSON.parse(reader.result);
                this.$store.dispatch("alertMessageModule/updateMessage", {
                  message: result.ErrorMessage || "未知錯誤",
                  status: "danger",
                });
              } catch {
                this.$store.dispatch("alertMessageModule/updateMessage", {
                  message: "回傳資料解析錯誤",
                  status: "danger",
                });
              }
              this.screen.close();
            };
            reader.readAsText(response.data);
          } else {
            // 如果是 Blob，處理下載
            const filename = decodeURIComponent(
              decodeURIComponent(
                response.headers["content-disposition"].split("filename*=UTF-8''")[1]
              )
            );

            // 將檔名中的副檔名部分修改為 .pdf
            const sanitizedFilename = filename.replace(/\.[^.]+$/, ".pdf");

            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = sanitizedFilename;
            a.click();
            window.URL.revokeObjectURL(url);
            this.screen.close();
          }
        })
        .catch(() => {
          this.$store.dispatch("alertMessageModule/updateMessage", {
            message: "下載失敗",
            status: "danger",
          });
          this.screen.close();
        });
    },

    // 送出匯入
    sendUpload() {
      const vm = this;
      vm.screen = this.openLoading();
      const api = `${window.BaseUrl.api}/Eva/Result/ExcelToPerson`;
      vm.$http
        .post(api, vm.uploadTemp)
        .then((response) => {
          if (response.data.ErrorMessage) {
            vm.$refs.upload.clearFiles();
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
            vm.screen.close();
          } else {
            this.searchForm.EmpNo = response.data.Data.map((item) => {
              return item.EmpNo;
            });
            vm.dialogUploadVisible = false;
            vm.screen.close();
          }
        })
        .catch((error) => {
          vm.$refs.upload.clearFiles();
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });

          vm.screen.close();
        });
    },

    getDataList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result`;
      const params = {
        ...this.searchForm,
      };

      this.$http
        .post(url, params)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.dataList = response.data.Data;
            this.keepEmpNoForExport = [...this.searchForm.EmpNo];
            this.searchForm.EmpNo = [];
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    getReleaseList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/Release`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.releaseList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    getCompanyList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/Company`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.companyList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    changeCompany(guid) {
      this.searchForm.DepartmentGuids = null;
      if (guid) {
        this.screen = this.openLoading();
        const url = `${window.BaseUrl.api}/Eva/Result/Options/Department/${guid}`;
        this.$http
          .get(url)
          .then((response) => {
            if (response.data.ErrorMessage) {
              this.$notify({
                title: "錯誤",
                message: response.data.ErrorMessage,
                type: "error",
                duration: 0,
              });
            } else {
              this.departmentList = response.data.Data;
            }
            this.screen.close();
          })
          .catch((error) => {
            this.$notify({
              title: "錯誤",
              message: error.response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
            this.screen.close();
          });
      } else {
        this.departmentList = null;
      }
    },

    getDepartmentList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/Department/${this.searchForm.CompanyGuid}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.departmentList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    getTitleList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/Title`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.titleList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    getSexList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/Sex`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.sexList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    getDegreeList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/Degree`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.degreeList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    getSchoolList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/School`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.schoolList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    getNatureList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/Nature`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.natureList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    //取得人員流水號
    getVirtualEmp() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Release/Recruit/VirtualEmp`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.virtualEmpList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    openDetailModal(row) {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/${row.EvaluationPersonGuid}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.detailModal = {
              ...response.data.Data,
            };
            $("#detailModal").modal("show");
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    // 檢視對應模板
    openPatternModal(row) {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Pattern/${row.EvaluationPersonGuid}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.patternModal = {
              ...response.data.Data,
            };
            this.dialogVisible = true;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    // 匯出資料
    exportData() {
      const vm = this;
      const api = `${window.BaseUrl.api}/Eva/Result/Excel`;
      vm.screen = this.openLoading();
      const params = {
        ...this.searchForm,
        EmpNo: this.keepEmpNoForExport,
      };
      this.$http
        .post(api, params, { responseType: "blob" })
        .then(async (response) => {
          // 檢查 Content-Type
          const contentType = response.headers["content-type"];
          if (contentType.includes("application/json")) {
            // 回傳為 JSON，解析錯誤訊息
            const reader = new FileReader();
            reader.onload = () => {
              const result = JSON.parse(reader.result);
              if (result.ErrorMessage) {
                this.$store.dispatch("alertMessageModule/updateMessage", {
                  message: result.ErrorMessage || "未知錯誤",
                  status: "danger",
                });
              }
              this.screen.close();
            };
            reader.readAsText(response.data);
          } else {
            // 回傳為 Blob，處理下載
            const filename = decodeURIComponent(
              decodeURIComponent(
                response.headers["content-disposition"].split("filename*=UTF-8''")[1]
              )
            );
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
            this.screen.close();
          }
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage || "未知錯誤",
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    //取得分類
    getCategoryOptions() {
      this.screen = this.openLoading();
      this.params = [];
      const url = `${window.BaseUrl.api}/system/config`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            let arr = [];
            arr = Object.keys(response.data.Data);

            for (let i = 1; i < arr.length; ++i) {
              this.params.push({
                title: arr[i],
                content: response.data.Data[arr[i]],
              });
            }
          }

          let modal = this.params.find((item) => {
            return item.title === "人格特質模板維護";
          });

          let category = modal.content.find((item) => {
            return item.ConfigKey === "ModuleCategoty";
          });

          this.categoryOptions = category.Value.split("、").map((item) => {
            return {
              value: item,
            };
          });

          // 找到 title === "測驗結果管理"
          const targetTitle = this.params.find((item) => item.title === "測驗結果管理");

          // 找到 content 中 ConfigKey === "TestResultQueryRemark"
          if (targetTitle) {
            // 找到 ConfigKey === "TestResultQueryRemark"
            const queryRemarkItem = targetTitle.content.find(
              (contentItem) => contentItem.ConfigKey === "TestResultQueryRemark"
            );
            if (queryRemarkItem) {
              this.searchNote = queryRemarkItem.Value;
            } else {
              console.error("找不到符合 ConfigKey === 'TestResultQueryRemark' 的項目");
            }

            // 找到 ConfigKey === "TestResultImportRemark"
            const importRemarkItem = targetTitle.content.find(
              (contentItem) => contentItem.ConfigKey === "TestResultImportRemark"
            );
            if (importRemarkItem) {
              this.uploadNote = importRemarkItem.Value;
            } else {
              console.error("找不到符合 ConfigKey === 'TestResultImportRemark' 的項目");
            }
          } else {
            console.error("找不到符合 title 的項目");
          }

          this.screen.close();
        })
        .catch(() => {
          this.screen.close();
        });
    },
  },
  created() {
    this.getReleaseList();
    this.getCompanyList();
    this.getTitleList();
    this.getSexList();
    this.getDegreeList();
    this.getSchoolList();
    this.getNatureList();
    this.getCategoryOptions();
    this.getVirtualEmp();
  },
};
</script>

<template>
  <div>
    <div class="page-name">
      <span>
        <i class="fas fa-map-marker-alt"></i>

        {{ i18n("Release.QuizPostSettings") }}
      </span>
    </div>

    <div class="filter-block">
      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.Year") }}</span>
        <select v-model="searchForm.year">
          <option :value="null" selected>{{ i18n("Basic.All") }}</option>
          <option v-for="year in yearList" :key="year" :value="year">
            {{ year }}
          </option>
        </select>
      </div>

      <div class="filter-field">
        <span class="title"> {{ i18n("Release.QuizNumber") }} </span>
        <input type="type" name="seqNo" v-model="searchForm.seqNo" />
      </div>
      <div class="filter-field">
        <span class="title"> {{ i18n("Basic.Name") }} </span>
        <input type="type" name="name" v-model="searchForm.name" />
      </div>

      <div class="filter-btn-wrap">
        <a class="btn btn-blue mr-2" @click.prevent="getReleaseList">{{
          i18n("Basic.Search")
        }}</a>
      </div>
    </div>

    <div class="text-right mb-2">
      <a class="btn btn-blue mr-2" v-if="isMailer" @click.prevent="openMailModal()">{{
        i18n("Custom.LetterMaintenance")
      }}</a>
      <a class="btn btn-blue" @click.prevent="openReleaseModal(true)">{{
        i18n("Basic.Add")
      }}</a>
    </div>
    <vue-bootstrap4-table
      :rows="releaseList"
      :columns="columns"
      :config="config"
      :classes="classes"
    >
      <template slot="func" slot-scope="props">
        <div style="text-align: left">
          <button
            class="btn btn-sm btn-blue mr-2"
            @click.prevent="openReleaseModal(false, props.row)"
          >
            {{ i18n("Basic.Edit") }}
          </button>
          <a
            class="btn btn-sm btn-info mr-2"
            @click.prevent="sendReminder(props.row.Guid)"
            >{{ i18n("Custom.SendReminder") }}</a
          >
          <br />
          <button class="btn btn-sm btn-primary mr-2" @click.prevent="clone(props.row)">
            {{ i18n("Custom.Copy") }}
          </button>
          <a
            class="btn btn-sm btn-blue"
            @click.prevent="openReMatchModal(props.row.Guid)"
            >{{ i18n("Custom.RecalculateTemplate") }}</a
          >
        </div>
      </template>
    </vue-bootstrap4-table>

    <!-- Release modal -->
    <div class="modal fade" id="ReleaseModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <el-form
          :model="releaseModal"
          label-position="top"
          ref="releaseModal"
          class="modal-content"
        >
          <div class="modal-header">
            <template v-if="modalTitle === 'new'">{{ i18n("Basic.Add") }}</template>
            <template v-if="modalTitle === 'edit'">{{ i18n("Basic.Edit") }}</template>
            <template v-if="modalTitle === 'copy'">{{ i18n("Custom.Copy") }}</template
            >{{ i18n("Release.QuizPost") }}
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-end">
                  <el-form-item
                    style="flex: 1"
                    :label="i18n('Release.QuizNumber')"
                    prop="SeqNo"
                  >
                    <el-input v-model="releaseModal.SeqNo" readonly></el-input>
                  </el-form-item>
                  <button
                    style="margin-bottom: 22px"
                    class="btn btn-sm btn-info mr-2"
                    @click.prevent="openBulletinModal()"
                  >
                    {{ i18n("Release.QuizAnnouncement") }}
                  </button>
                  <button
                    style="margin-bottom: 22px"
                    class="btn btn-sm btn-info mr-2"
                    @click.prevent="openRemarkModal()"
                  >
                    {{ i18n("Release.BasicInformationDescription") }}
                  </button>

                  <button
                    style="margin-bottom: 22px"
                    class="btn btn-sm btn-info mr-2"
                    @click.prevent="openAnswerLimitMemoModal()"
                  >
                    {{ i18n("Custom.AnswerOverdueReminder") }}
                  </button>
                </div>
              </div>

              <div class="col-md-12">
                <el-form-item
                  style="flex: 1"
                  :label="i18n('Release.QuizPostName')"
                  prop="Name"
                  :rules="{
                    required: true,
                    message: 'required',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-input v-model="releaseModal.Name"></el-input>
                </el-form-item>
              </div>
              <div class="col-md-8">
                <el-form-item
                  :label="i18n('Release.QuestionBankVersion')"
                  prop="VersionGuid"
                  :rules="{
                    required: true,
                    message: 'required',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-select
                    style="width: 100%"
                    v-model="releaseModal.VersionGuid"
                    placeholder="select"
                  >
                    <el-option
                      v-for="book in bookList"
                      :key="book.Guid"
                      :label="book.VersionName"
                      :value="book.Guid"
                      :disabled="!book.IsEnabled"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="col-md-4">
                <el-form-item
                  :label="i18n('Custom.TestForInterviewers')"
                  prop="IsInterview"
                  :rules="{
                    required: true,
                    message: 'required',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-select
                    style="display: block"
                    v-model="releaseModal.IsInterview"
                    clearable
                    :placeholder="i18n('Basic.PleaseSelect')"
                  >
                    <el-option value="是" :label="i18n('Basic.Yes')"></el-option>
                    <el-option value="否" :label="i18n('Basic.No')"></el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="col-md-12">
                <el-form-item :label="i18n('Custom.AnswerTimeoutSeconds')">
                  <el-input
                    style="width: 100%"
                    type="number"
                    v-model="releaseModal.AnswerLimit"
                  />
                </el-form-item>
              </div>

              <div class="col-md-12">
                <el-form-item
                  :label="i18n('Release.TestStartDate')"
                  prop="DateRange"
                  :rules="{
                    required: true,
                    message: 'required',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-date-picker
                    style="width: 100%"
                    :picker-options="pickerOptions"
                    v-model="releaseModal.DateRange"
                    value-format="yyyy-MM-ddTHH:mm:ss"
                    type="datetimerange"
                    range-separator="-"
                    :start-placeholder="i18n('Basic.StartDate')"
                    :end-placeholder="i18n('Basic.EndDate')"
                    @change="setNotifyDefault"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="col-md-12">
                <el-form-item
                  :label="i18n('Release.QuizNotificationTime')"
                  prop="InformTime"
                  :rules="{
                    required: true,
                    message: 'required',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-date-picker
                    :picker-options="pickerOptions"
                    style="width: 100%"
                    value-format="yyyy-MM-ddTHH:mm:ss"
                    v-model="releaseModal.InformTime"
                    type="datetime"
                    placeholder="Select date"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
            </div>
            <div class="text-right">
              <el-checkbox
                v-model="releaseModal.CheckEmail"
                true-label="Y"
                false-label="N"
                style="margin-right: 10px"
                >{{ i18n("Custom.VerifyEmail") }}</el-checkbox
              >

              <button class="btn btn-blue mr-2" @click.prevent="openPersonModal()">
                {{ i18n("Basic.Add") }}
              </button>
              <button class="btn btn-blue" @click.prevent="openUploadModal()">
                {{ i18n("Custom.ImportPersonnel") }}
              </button>
            </div>
            <table class="table table-striped table-light table-hover">
              <thead>
                <tr>
                  <th>{{ i18n("Basic.Department") }}</th>
                  <th>{{ i18n("Custom.PersonnelNumber") }}</th>
                  <th>{{ i18n("Basic.Employee") }}</th>
                  <th>{{ i18n("Custom.Function") }}</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(Person, index) in releaseModal.Persons"
                  :key="Person.UserGuid"
                >
                  <td>{{ Person.DeptSName }}</td>
                  <td>{{ Person.EmpNo }}</td>
                  <td>{{ Person.EmpCName }}</td>
                  <td>
                    <button class="btn btn-sm btn-danger" @click="deletePerson(index)">
                      {{ i18n("Custom.RemovePerson") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ i18n("Basic.Cancel") }}
            </button>
            <button type="button" class="btn btn-blue" @click.prevent="saveRelease()">
              {{ i18n("Basic.Save") }}
            </button>
          </div>
        </el-form>
      </div>
    </div>

    <!-- 公告modal -->
    <div class="modal fade bg-modal" id="BulletinModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{ i18n("Release.QuizAnnouncement") }}</div>

          <div class="modal-body">
            <vue-editor
              v-model="bulletinHtml"
              :editorToolbar="customToolbar"
            ></vue-editor>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="bulletinHtml = tempBulletin"
            >
              {{ i18n("Basic.Cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="saveBulletin"
            >
              {{ i18n("Basic.Confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 基本資料modal -->
    <div class="modal fade bg-modal" id="RemarkModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            {{ i18n("Release.BasicInformationDescription") }}
          </div>

          <div class="modal-body">
            <vue-editor v-model="remarkHtml" :editorToolbar="customToolbar"></vue-editor>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="remarkHtml = tempRemark">
              {{ i18n("Basic.Cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="saveRemark"
            >
              {{ i18n("Basic.Confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 作答逾時提醒modal -->
    <div class="modal fade" id="LimitMemoModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{ i18n("Custom.AnswerOverdueReminder") }}</div>

          <div class="modal-body">
            <vue-editor
              v-model="answerLimitMemoHtml"
              :editorToolbar="customToolbar"
            ></vue-editor>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="answerLimitMemoHtml = tempMemo">
              {{ i18n("Basic.Cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="saveAnswerLimitMemo"
            >
              {{ i18n("Basic.Confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 信件維護 -->
    <el-dialog
      :title="i18n('Custom.LetterMaintenance')"
      :visible.sync="mailDialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <label> {{ i18n("Custom.LetterType") }}</label>
      <el-select
        v-model="mailDetail.category"
        @change="getMailDetail"
        :placeholder="i18n('Custom.PleaseSelectTheLetterType')"
        style="width: 100%; margin-bottom: 20px"
      >
        <el-option
          v-for="item in mailList"
          :key="item.Value"
          :label="item.Text"
          :value="item.Value"
        >
        </el-option>
      </el-select>
      <el-form
        :model="mailDetail"
        ref="mailValidateForm"
        label-position="top"
        v-if="mailDetail.category"
      >
        <el-form-item
          prop="Subject"
          :label="i18n('Custom.SubjectOfTheLetter')"
          :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
        >
          <el-input v-model="mailDetail.Subject"></el-input>
        </el-form-item>

        <el-form-item
          prop="Body"
          :label="i18n('Custom.LetterContent')"
          :rules="[{ required: true, message: 'required', trigger: 'blur' }]"
        >
          <div style="background-color: #fff">
            <vue-editor
              v-model="mailDetail.Body"
              :editorToolbar="customToolbar"
            ></vue-editor>
          </div>
          <span class="el-form-item__error" v-if="!mailDetail.Body">required</span>
        </el-form-item>

        <el-form-item :label="i18n('Custom.LetterParameters')">
          <div class="letter-parameters">
            <div v-for="parameter in mailDetail.ArgumentInfos" :key="parameter.Name">
              <span>{{ parameter.Name }}</span>
              {{ parameter.PropertyName }}
            </div>
          </div>
          <span class="el-form-item__error" v-if="!mailDetail.Body">required</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="mailDialogVisible = false">{{
          i18n("Basic.Close")
        }}</el-button>
        <el-button type="primary" @click="saveMail()" v-if="mailDetail.category">{{
          i18n("Basic.Save")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 重算對應模板 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="i18n('Custom.RecalculateTemplate')"
      :visible.sync="reMatchDialogVisible"
      ref="logicModal"
      append-to-body
      width="30%"
    >
      <div class="mb-2">{{ reMatchModal.Message }}</div>
      <el-progress
        :text-inside="true"
        :stroke-width="26"
        :percentage="reMatchModal.Percent"
      ></el-progress>

      <br /><br />
      <div class="text-center">
        <el-button
          @click="startReMatch()"
          v-if="reMatchModal.Status === 0"
          type="primary"
          size="mini"
          >{{ i18n("Custom.StartMatching") }}</el-button
        >
        <el-button
          @click="endReMatch()"
          v-if="reMatchModal.Status === 3 || reMatchModal.Status === 5"
          type="danger"
          size="mini"
          >{{ i18n("Custom.DeleteMatch") }}</el-button
        >
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="reMatchDialogVisible = false">{{
          i18n("Basic.Close")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 人員匯入資料 Modal-->
    <el-dialog
      :title="i18n('Custom.PersonnelImport')"
      :visible.sync="dialogUploadVisible"
    >
      <div>
        <u style="color: midnightblue; cursor: pointer" @click.prevent="downloadFile">{{
          i18n("Custom.TemplateDownload")
        }}</u>

        <div style="display: flex">
          <el-upload
            ref="upload"
            action
            accept=".xls,.xlsx"
            :auto-upload="false"
            :show-file-list="true"
            :on-change="uploadFile"
            :limit="1"
          >
            <el-button size="small" type="primary">{{
              i18n("Basic.ClickUpload")
            }}</el-button>
          </el-upload>

          <div style="flex: 1; min-width: 1px; margin-left: 10px">
            <pre>{{ note }}</pre>
          </div>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="dialogUploadVisible = false">{{
          i18n("Basic.Cancel")
        }}</el-button>
        <el-button type="primary" @click="sendUpload()">{{
          i18n("Basic.Confirm")
        }}</el-button>
      </div>
    </el-dialog>

    <PersonSelect
      @batchPerson="batchPerson"
      :selectPersons="persons"
      :departments="departments"
      :isInterviewer="releaseModal.IsInterview === '是' ? true : false"
    />
  </div>
</template>

<script>
import $ from "jquery";
import { mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import PersonSelect from "./PersonSelect.vue";

export default {
  data() {
    return {
      screen: null, // loading遮罩
      dialogUploadVisible: false, //人員匯入modal
      uploadTemp: {}, //匯入暫存

      modalTitle: null,
      releaseIsNew: true,
      releaseModal: {
        Announcement:null,
        Remark:null,
        AnswerLimitMemo:null,
        DateRange: [],
        Persons: [],
      },
      persons: [],

      config: {
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 10,
        per_page_options: [10, 25, 50],
        global_search: {
          visibility: false,
        },
        // checkbox_rows: true,
      },
      bookList: [],
      yearList: [],
      releaseList: [],
      searchForm: {
        year: null,
      },
      bulletinHtml: null,
      tempBulletin: null,
      remarkHtml: null,
      tempRemark:null,
      answerLimitMemoHtml: null,
      tempMemo:null,
      classes: {
        table: "table-light table-striped",
        // via: 'table-danger'
      },

      mailDialogVisible: false, //信件維護modal
      mailList: [], //信件種類下拉選單
      mailDetail: {}, //信件維護內容

      reMatchDialogVisible: false, //重算對應模板的dialog
      reMatchModal: {}, //重算對應模板的內容
      currentReleaseGuid: null,

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date().getTime() - 8.64e7;
        },
      },

      note: null,
      customToolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ color: [] }, { background: [] }],
      ],
      departments: [], //
      isMailer: false,
    };
  },
  components: {
    PersonSelect,
    VueEditor,
  },
  computed: {
    columns() {
      const col = [
        {
          label: this.i18n("Release.QuizNumber"),
          name: "SeqNo",
          sort: true,
        },
        {
          label: this.i18n("Release.QuizPostName"),
          name: "Name",
          sort: true,
        },
        {
          label: this.i18n("Custom.Year"),
          name: "Year",
          sort: true,
        },
        {
          label: this.i18n("Release.TestStartDate"),
          name: "StartDate",
          sort: true,
        },
        {
          label: this.i18n("Release.TestEndDate"),
          name: "EndDate",
          sort: true,
        },
        {
          label: this.i18n("Release.NumberPeopleTested"),
          name: "PersonCount",
          sort: true,
        },
        {
          label: this.i18n("Custom.Function"),
          name: "func",
          slot_name: "func",
        },
      ];
      return col;
    },
  },
  methods: {
    ...mapActions("personModule", ["getPerson", "getSetting", "getOption"]),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    getReleaseList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Release`;
      const params = {
        ...this.searchForm,
      };
      this.$http
        .get(url, { params })
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.releaseList = response.data.Data.map((item) => ({
              ...item,
              StartDate: this.dayjs(String(item.StartDate)).format("YYYY/MM/DD HH:mm:ss"),
              EndDate: this.dayjs(String(item.EndDate)).format("YYYY/MM/DD HH:mm:ss"),
            }));
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    //取得搜尋條件 年度
    getYearList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Release/Year`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.yearList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    // 下載範例檔
    downloadFile() {
      const vm = this;
      vm.screen = this.openLoading();
      const api = `${window.BaseUrl.api}/Eva/Release/ExcelToPerson`;

      vm.$http
        .get(api, { responseType: "blob" })
        .then((response) => {
          const filename = decodeURIComponent(
            decodeURIComponent(
              response.headers["content-disposition"].split("filename*=UTF-8''")[1]
            )
          );

          const blob = new Blob([response.data]);
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    // 打開匯入modal
    openUploadModal() {
      const vm = this;
      vm.dialogUploadVisible = true;
      vm.$nextTick(function () {
        vm.$refs.upload.clearFiles();
      });
    },

    // 匯入資料 API
    uploadFile(file) {
      const vm = this;
      let fd = new FormData();
      fd.append("file", file.raw);
      vm.uploadTemp = fd;
    },

    // 送出匯入
    sendUpload() {
      const vm = this;
      vm.screen = this.openLoading();
      const api = `${window.BaseUrl.api}/Eva/Release/ExcelToPerson`;
      vm.$http
        .post(api, vm.uploadTemp)
        .then((response) => {
          if (response.data.ErrorMessage) {
            vm.$refs.upload.clearFiles();
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
            vm.screen.close();
          } else {
            response.data.Data.forEach((item) => {
              const hasPerson = vm.releaseModal.Persons.find((rItem) => {
                return item.UserGuid === rItem.UserGuid;
              });

              if (!hasPerson) {
                vm.releaseModal.Persons.push(item);
              }
            });

            vm.dialogUploadVisible = false;
            vm.screen.close();
          }
        })
        .catch((error) => {
          vm.$refs.upload.clearFiles();
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });

          vm.screen.close();
        });
    },

    //重算對應模板
    openReMatchModal(guid) {
      const vm = this;
      vm.currentReleaseGuid = guid;
      vm.reMatchModal = {};
      vm.getReMatch(guid);
      vm.reMatchDialogVisible = true;
    },

    //取得重算進度
    getReMatch(guid) {
      const url = `${window.BaseUrl.api}/Eva/Release/ReMatch/${guid}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
            });
          } else {
            this.reMatchModal = {
              ...response.data.Data,
            };
            if (
              this.reMatchDialogVisible === true &&
              this.reMatchModal.FinishTime === null &&
              this.reMatchModal.Status !== 0
            ) {
              setTimeout(() => {
                this.getReMatch(guid);
              }, "1000");
            }
          }
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
          });
        });
    },

    //開始重算
    startReMatch() {
      const url = `${window.BaseUrl.api}/Eva/Release/ReMatch/${this.currentReleaseGuid}`;
      this.$http
        .post(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
            });
          } else {
            this.getReMatch(this.currentReleaseGuid);
          }
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
          });
        });
    },

    //終止重算
    endReMatch() {
      const url = `${window.BaseUrl.api}/Eva/Release/ReMatch/${this.currentReleaseGuid}`;
      this.$http
        .delete(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
            });
          } else {
            this.getReMatch(this.currentReleaseGuid);
          }
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
          });
        });
    },

    //寄送提醒信
    sendReminder(guid) {
      const url = `${window.BaseUrl.api}/Eva/Release/Remind/${guid}`;
      this.$http
        .post(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
            });
          } else {
            this.reMatchModal = {
              ...response.data.Data,
            };

            this.$notify({
              message: response.data.Data,
              type: "success",
            });
          }
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
          });
        });
    },
    getBookList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Ver`;
      const params = {
        // IsEnable: true,
      };
      this.$http
        .get(url, { params })
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.bookList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    async getSeqNo() {
      const url = `${window.BaseUrl.api}/Eva/Release/TestSeriesNo/Add`;
      try {
        const response = await this.$http.post(url);
        if (response.data.ErrorMessage) {
          this.$notify({
            title: "錯誤",
            message: response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
        } else {
          return response.data.Data;
        }
      } catch (error) {
        this.$notify({
          title: "錯誤",
          message: error.response?.data?.ErrorMessage || error.message,
          type: "error",
          duration: 0,
        });
        throw error;
      } finally {
        this.screen.close();
      }
    },

    async openReleaseModal(isNew, release) {
      try {
        this.getBookList();
        if (isNew) {
          this.modalTitle = "new";
        } else {
          this.modalTitle = "edit";
        }

        this.releaseIsNew = isNew;
        this.bulletinHtml = null;
        this.tempBulletin = null;
        this.remarkHtml = null;
        this.tempRemark = null;
        this.answerLimitMemoHtml = null;
        this.tempMemo=null;

        if (!this.releaseIsNew) {
          this.screen = this.openLoading();
          const url = `${window.BaseUrl.api}/Eva/Release/${release.Guid}`;
          this.$http
            .get(url)
            .then((response) => {
              if (response.data.ErrorMessage) {
                this.$notify({
                  title: "錯誤",
                  message: response.data.ErrorMessage,
                  type: "error",
                  duration: 0,
                });
              } else {
                this.releaseModal = {
                  ...response.data.Data,
                  SeqNo: release.SeqNo,
                  DateRange: [response.data.Data.StartDate, response.data.Data.EndDate],
                  CheckEmail: "Y",
                };
              }
              this.screen.close();
            })
            .catch((error) => {
              this.$notify({
                title: "錯誤",
                message: error.response.data.ErrorMessage,
                type: "error",
                duration: 0,
              });
              this.screen.close();
            });
        } else {
          let newSeqNo = await this.getSeqNo();
          let configData = await this.getConfig();

          this.releaseModal = {
            SeqNo: newSeqNo,
            Name: newSeqNo,
            DateRange: [],
            Persons: [],
            InformTime: null,
            CheckEmail: "Y",
            Announcement:configData.Data["測驗公告"][0].Value,
            Remark:configData.Data["測驗基本資料頁面說明"][0].Value,
            AnswerLimitMemo:configData.Data["作答逾時提醒"][0].Value
          };
        }

        $("#ReleaseModal").modal("show");

        this.$nextTick(() => {
          this.$refs.releaseModal.clearValidate();
        });
      } catch (error) {
        console.error("Failed to open release modal:", error);
      }
    },

    clone(release) {
      this.modalTitle = "copy";
      this.getBookList();
      this.releaseIsNew = true;
        this.bulletinHtml = null;
        this.tempBulletin = null;
        this.remarkHtml = null;
        this.tempRemark = null;
        this.answerLimitMemoHtml = null;
        this.tempMemo=null;
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Release/${release.Guid}`;
      this.$http
        .get(url)
        .then(async (response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            let newSeqNo = await this.getSeqNo();
            this.releaseModal = {
              ...response.data.Data,
              SeqNo: newSeqNo,
              Name: newSeqNo,
              DateRange: [],
              InformTime: null,
              CheckEmail: "Y",
            };
          }
          this.screen.close();
          $("#ReleaseModal").modal("show");

          this.$nextTick(() => {
            this.$refs.releaseModal.clearValidate();
          });
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    openPersonModal() {
      this.persons = this.releaseModal.Persons;
      this.getPerson();
      this.getSetting();
      this.getOption();
      this.getDepartments();
    },

    getDepartments() {
      const url = `${window.BaseUrl.api}/Org/personelrange`;

      this.screen = this.openLoading();
      this.$http
        .get(url)
        .then((response) => {
          let departments = response.data.Data.Departments.map((item) => ({
            id: item.Name,
            label: item.Name,
            customLabel: item.Name,
            code: item.Code,
          }));

          if (this.releaseModal.IsInterview === "是") {
            this.departments = departments.filter((item) => {
              return item.code.slice(0, 2) === "IV";
            });
          } else if (this.releaseModal.IsInterview === "否") {
            this.departments = departments.filter((item) => {
              return item.code.slice(0, 2) !== "IV";
            });
          } else {
            this.departments = departments;
          }

          this.$nextTick(() => {
            $("#addpersonModal").modal("show");
          });

          this.screen.close();
        })
        .catch((error) => {
          console.error(error);
          this.screen.close();
        });
    },

    //  批次新增人員至角色
    batchPerson(persons) {
      this.releaseModal.Persons = persons;
    },

    //取得參數設定
    async getConfig() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/system/config`;

      try {
        const response = await this.$http.get(url);
        if (response.data.ErrorMessage) {
          this.$notify({
            title: "錯誤",
            message: response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
        } else {
          return response.data;
        }
      } catch (error) {
        this.$notify({
          title: "錯誤",
          message: error.response?.data?.ErrorMessage || error.message,
          type: "error",
          duration: 0,
        });
        throw error;
      } finally {
        this.screen.close();
      }
    },

    async openBulletinModal() {
      if (this.tempBulletin) {
        this.bulletinHtml = this.tempBulletin;
        console.log("11");
      } else {
        this.bulletinHtml = this.releaseModal.Announcement
            ? this.releaseModal.Announcement
            : null;
      }

      $("#BulletinModal")
        .modal({
          backdrop: "static",
          keyboard: false,
        })
        .modal("show");
    },
    saveBulletin() {
      this.tempBulletin = JSON.parse(JSON.stringify(this.bulletinHtml));
      this.releaseModal.Announcement = this.bulletinHtml;
    },

    async openRemarkModal() {
      if (this.tempRemark) {
        this.remarkHtml = this.tempRemark;
      } else {
        this.remarkHtml = this.releaseModal.Remark ? this.releaseModal.Remark : null;
      }



      $("#RemarkModal")
        .modal({
          backdrop: "static",
          keyboard: false,
        })
        .modal("show");
    },
    saveRemark() {
      this.tempRemark = JSON.parse(JSON.stringify(this.remarkHtml));
      this.releaseModal.Remark = this.remarkHtml;
    },

    async openAnswerLimitMemoModal() {
      if (this.tempMemo) {
        this.answerLimitMemoHtml = this.tempMemo;
      }else{
        this.answerLimitMemoHtml = this.releaseModal.AnswerLimitMemo
            ? this.releaseModal.AnswerLimitMemo
            : null;
      }

      $("#LimitMemoModal")
        .modal({
          backdrop: "static",
          keyboard: false,
        })
        .modal("show");
    },

    saveAnswerLimitMemo() {
      this.tempMemo = JSON.parse(JSON.stringify(this.answerLimitMemoHtml));
      this.releaseModal.AnswerLimitMemo = this.answerLimitMemoHtml;
    },

    //打開信件維護
    openMailModal() {
      this.screen = this.openLoading();
      this.mailDetail = {};
      const url = `${window.BaseUrl.api}/MailTemplate/Options/Categories`;

      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.mailList = response.data.Data;
          }
          this.screen.close();
          this.mailDialogVisible = true;
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    //種類切換時，取得信件的內容
    getMailDetail(category) {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/MailTemplate/${category}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.$set(this.mailDetail, "Subject", response.data.Data.Subject);
            this.$set(this.mailDetail, "Body", response.data.Data.Body);
            this.$set(this.mailDetail, "ArgumentInfos", response.data.Data.ArgumentInfos);
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    //儲存信件內容
    saveMail() {
      const vm = this;
      vm.$refs["mailValidateForm"].validate((valid) => {
        if (valid) {
          vm.screen = vm.openLoading();
          const data = {
            Subject: vm.mailDetail.Subject,
            Body: vm.mailDetail.Body,
          };

          const url = `${window.BaseUrl.api}/MailTemplate/${vm.mailDetail.category}`;

          vm.$http.put(url, data).then((response) => {
            if (response.data.ErrorMessage) {
              vm.$notify({
                title: "錯誤",
                message: response.data.ErrorMessage,
                type: "error",
                duration: 0,
              });
            } else {
              vm.$notify({
                message: response.data.Data,
                type: "success",
              });
            }

            vm.screen.close();
            vm.mailDialogVisible = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    saveRelease() {
      // eslint-disable-next-line consistent-return
      this.$refs.releaseModal.validate((valid) => {
        if (valid) {
          const vm = this;
          const data = {
            ...this.releaseModal,
            StartDate: this.releaseModal.DateRange[0],
            EndDate: this.releaseModal.DateRange[1],
            PersonsGuid: this.releaseModal.Persons.map((item) => item.UserGuid),
          };
          vm.screen = this.openLoading();
          const httpMethod = vm.releaseIsNew ? "post" : "put";
          const api = vm.releaseIsNew
            ? `${window.BaseUrl.api}/Eva/Release`
            : `${window.BaseUrl.api}/Eva/Release/${vm.releaseModal.Guid}`;

          vm.$http[httpMethod](api, data).then((response) => {
            if (response.data.ErrorMessage) {
              this.$notify({
                title: "錯誤",
                message: response.data.ErrorMessage,
                type: "error",
                duration: 0,
              });
            } else {
              this.getReleaseList();
              $("#ReleaseModal").modal("hide");

              this.$notify({
                message: vm.releaseIsNew ? "新增成功" : "編輯成功",
                type: "success",
              });
            }

            this.screen.close();
          });
        } else {
          return false;
        }
      });
    },

    deletePerson(index) {
      this.releaseModal.Persons.splice(index, 1);
    },

    //取得分類
    getNote() {
      this.screen = this.openLoading();
      let params = [];
      const url = `${window.BaseUrl.api}/system/config`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            let arr = [];
            arr = Object.keys(response.data.Data);

            for (let i = 1; i < arr.length; ++i) {
              params.push({
                title: arr[i],
                content: response.data.Data[arr[i]],
              });
            }
          }

          let modal = params.find((item) => {
            return item.title === "測驗發布設定";
          });

          let category = modal.content.find((item) => {
            return item.ConfigKey === "ImportEmployeeComment";
          });

          this.note = category.Value;

          this.screen.close();
        })
        .catch(() => {
          this.screen.close();
        });
    },

    //預設測驗通知日期
    setNotifyDefault(e) {
      this.releaseModal.InformTime = e[0];
    },
  },
  created() {
    $(document).on("show.bs.modal", ".modal", function () {
      const zIndex = 1040 + 10 * $(".modal:visible").length;
      $(this).css("z-index", zIndex);
      setTimeout(() => {
        $(".modal-backdrop")
          .not(".modal-stack")
          .css("z-index", zIndex - 1)
          .addClass("modal-stack");
      }, 0);
    });
    this.getYearList();
    this.getNote();

    let roles = localStorage.getItem("RoleName");
    let targetRole = "信件範本管理";

    // 創建正規表達式來精確匹配目標角色
    let regex = new RegExp(`(^|,)${targetRole}($|,)`);

    if (regex.test(roles)) {
      this.isMailer = true;
    } else {
      this.isMailer = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.bg-modal {
  .modal-body {
    background-image: url("~@/assets/Images/37130.jpg");
    background-size: cover;
    position: relative;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 41, 126, 0.6);
      z-index: 1;
    }
  }
}

.letter-parameters {
  & > div {
    margin: 3px 10px;
    padding-right: 5px;
    display: inline-block;
    background-color: #fff;
    & > span {
      background-color: #dbe2ff;
      display: inline-block;
      padding: 0 5px;
      margin-right: 5px;
      color: #000;
    }
  }
}
</style>
